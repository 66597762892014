.payu-card-section * {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #ffffff;
}

.payu-card-section {
  text-align: center;
  width: 420px;
  margin: 20px auto 10px;
  display: block;
  border-radius: 5px;
  box-sizing: border-box;
}

.payu-card-section .card-container {
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  padding: 2px;
  background: rgb(2, 0, 60);
  text-align: left;
  box-sizing: border-box;
}

.payu-card-section .payu-card-form {
  background-color: #ffffff;
  padding: 15px 10px;
  border-radius: 4px;
}

.payu-card-section .response-success {
  color: #438f29;
}

.payu-card-section .response-error {
  color: #990000;
}
